import PropTypes from "prop-types";
import React, { Component } from "react";
import Logo from "../assets/images/logo.png";
import Papers from "../assets/images/old-papers.png";

import "../assets/styles/components/header.css";

class Header extends Component {
  render() {
    return (
      <header id="site-header">
        <div className="logo">
          <figure className="logo__image">
            <a href="/" aria-label="Back to Homepage" title="Back to Homepage">
              <div className="logo__image--left">
                <h1>Archive of</h1>
                <img
                  src={Logo}
                  alt={this.props.siteTitle}
                />
                <p>Circulated 1674 - 1951</p>
              </div>
              <div className="logo__image--right">
                <img
                  src={Papers}
                  alt={this.props.siteTitle}
                />
              </div>
            </a>
          </figure>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
